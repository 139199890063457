@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
        url('SFProDisplay-Medium.woff2') format('woff2'),
        url('SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
        url('SFProDisplay-Heavy.woff2') format('woff2'),
        url('SFProDisplay-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display UltralightItalic'), local('SFProDisplay-UltralightItalic'),
        url('SFProDisplay-UltralightItalic.woff2') format('woff2'),
        url('SFProDisplay-UltralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Regular Italic'), local('SFProDisplay-RegularItalic'),
        url('SFProDisplay-RegularItalic.woff2') format('woff2'),
        url('SFProDisplay-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
        url('SFProDisplay-Thin.woff2') format('woff2'),
        url('SFProDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
        url('SFProDisplay-Light.woff2') format('woff2'),
        url('SFProDisplay-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Black Italic'), local('SFProDisplay-BlackItalic'),
        url('SFProDisplay-BlackItalic.woff2') format('woff2'),
        url('SFProDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
        url('SFProDisplay-Bold.woff2') format('woff2'),
        url('SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
        url('SFProDisplay-Black.woff2') format('woff2'),
        url('SFProDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display SemiboldItalic'), local('SFProDisplay-SemiboldItalic'),
        url('SFProDisplay-SemiboldItalic.woff2') format('woff2'),
        url('SFProDisplay-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
        url('SFProDisplay-Ultralight.woff2') format('woff2'),
        url('SFProDisplay-Ultralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
        url('SFProDisplay-LightItalic.woff2') format('woff2'),
        url('SFProDisplay-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display ThinItalic'), local('SFProDisplay-ThinItalic'),
        url('SFProDisplay-ThinItalic.woff2') format('woff2'),
        url('SFProDisplay-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
        url('SFProDisplay-MediumItalic.woff2') format('woff2'),
        url('SFProDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
        url('SFProDisplay-Semibold.woff2') format('woff2'),
        url('SFProDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
        url('SFProDisplay-HeavyItalic.woff2') format('woff2'),
        url('SFProDisplay-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
        url('SFProDisplay-Regular.woff2') format('woff2'),
        url('SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
        url('SFProDisplay-BoldItalic.woff2') format('woff2'),
        url('SFProDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}