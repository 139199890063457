// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import "../assets/fonts/SFPro/stylesheet.css";

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ff69b4;
  --ion-color-primary-rgb: 255, 105, 180;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e05c9e;
  --ion-color-primary-tint: #ff78bc;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #888a9b;
  --ion-color-tertiary-rgb: 136, 138, 155;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #787988;
  --ion-color-tertiary-tint: #9496a5;

  /** success **/
  --ion-color-success: #57ac66;
  --ion-color-success-rgb: 87, 172, 102;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #4d975a;
  --ion-color-success-tint: #68b475;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #cf0034;
  --ion-color-danger-rgb: 207, 0, 52;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b6002e;
  --ion-color-danger-tint: #d41a48;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-orange: var(--ion-color-primary);
  --ion-color-orange-rgb: 255, 136, 86;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #e0784c;
  --ion-color-orange-tint: #ff9467;

  // --ion-color-purple: #464a6d;
  // --ion-color-purple-rgb: 70, 74, 109;
  // --ion-color-purple-contrast: #ffffff;
  // --ion-color-purple-contrast-rgb: 255, 255, 255;
  // --ion-color-purple-shade: #3e4160;
  // --ion-color-purple-tint: #595c7c;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-grey: #f0f1f5;
  --ion-color-grey-rgb: 240, 241, 245;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #d3d4d8;
  --ion-color-grey-tint: #f2f2f6;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  // --ion-font-family: "mostra_nuova_regularregular", sans-serif;
  --ion-font-family: "SF Pro Display", sans-serif;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

ion-toolbar {
  // height: 56px;
  vertical-align: middle;
  display: flex;

  ion-button {
    --opacity: 1 !important;
  }
}

ion-modal::part(content) {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}

.diet-info-popover {
  --width: 100% !important;
  --max-width: 90% !important;
}

.diet-info-popover::part(content) {
  border: none !important;
  overflow: visible !important;
  top: calc(30px + var(--ion-safe-area-left)) !important;
  border-radius: 0 0 10px 10px !important;
}

.diet-info-popover .sc-ion-popover-md::part(content) {
  margin: 0 auto;
}

.weekly-activity {
  --width: 80% !important;
  --max-width: 500px !important;
}

.workout-history-popover {
  --width: 95% !important;
  --max-width: 500px !important;
  --max-height: 80% !important;
}

.workout-history-popover::part(content) {
  border: 1px solid #000;
  overflow: hidden !important;
}

.weekly-activity::part(content) {
  border-radius: 7px !important;
  background: transparent !important;
}

.video-actions-popover {
  --width: 230px !important;
}

.coming-soon-popover {
  --width: 80% !important;
  --max-width: 500px !important;
}

.equipment-popover .sc-ion-popover-md::part(content) {
  position: static !important;
  margin: 0 auto;
  border-radius: 20px;
}

.coming-soon-popover .sc-ion-popover-ios::part(content) {
  transform: translateY(-50%) !important;
  top: 50% !important;
}

.coming-soon-popover .sc-ion-popover-md::part(content) {
  position: static !important;
  margin: 0 auto;
}

.video-actions-popover .popover-wrapper::part(content) {
  background: transparent;
  box-shadow: none !important;
  overflow: visible;
}

.workout-history-popover .sc-ion-popover-ios::part(content) {
  border-radius: 5px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.difficulty-level-popover {
  --width: 260px !important;
}

.difficulty-level-popover::part(content) {
  border-radius: 0 !important;
}

.custom-time-picker .picker-opt {
  text-overflow: unset !important;
}

.md .custom-time-picker ion-picker-column {
  max-width: unset !important;
}

.scroll ion-content {
  --overflow: scroll !important;
}

.full-screen-modal {
  --height: 100% !important;
  --width: 100% !important;
  --border-radius: 0 !important;
}

.orange {
  color: var(--ion-color-primary) !important;
}

.dark-purple {
  color: #464a6d !important;
}

.danger {
  color: var(--ion-color-danger) !important;
}
