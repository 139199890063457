/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "swiper/scss/lazy";
@import "swiper/scss/effect-coverflow";
@import "swiper/scss/autoplay";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";

$primaryMuscle: #9b3408;
$secondaryMuscle: #757fd7;
$tertiaryMuscle: #30b378;

:root {
  // --ion-safe-area-top: 20px;
  // --ion-safe-area-bottom: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
  50% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
  75% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInRightDelay {
  0% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }
  75% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }
  85% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }
  33% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes pulse-grow {
  0% {
    transform: scale3d(1, 1, 1);

    box-shadow: 0 0 0 0px rgba(var(--ion-color-primary-rgb), 1),
      0 0 0 0px rgba(var(--ion-color-primary-rgb), 1),
      0 0 0 0px rgba(var(--ion-color-primary-rgb), 1);
  }
  20% {
    box-shadow: 0 0 0 7px rgba(var(--ion-color-primary-rgb), 0.66),
      0 0 0 0px rgba(var(--ion-color-primary-rgb), 1),
      0 0 0 0px rgba(var(--ion-color-primary-rgb), 1);
  }
  40% {
    box-shadow: 0 0 0 14px rgba(var(--ion-color-primary-rgb), 0.33),
      0 0 0 7px rgba(var(--ion-color-primary-rgb), 0.66),
      0 0 0 0px rgba(var(--ion-color-primary-rgb), 1);
  }
  60% {
    box-shadow: 0 0 0 20px rgba(var(--ion-color-primary-rgb), 0),
      0 0 0 14px rgba(var(--ion-color-primary-rgb), 0.33),
      0 0 0 7px rgba(var(--ion-color-primary-rgb), 0.66);
    transform: scale3d(1, 1, 1);
  }
  80% {
    transform: scale3d(1.05, 1.05, 1);
    box-shadow: 0 0 0 20px rgba(var(--ion-color-primary-rgb), 0),
      0 0 0 20px rgba(var(--ion-color-primary-rgb), 0),
      0 0 0 14px rgba(var(--ion-color-primary-rgb), 0);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse-grow-clear {
  0% {
    transform: scale3d(1, 1, 1);
  }
  60% {
    transform: scale3d(1, 1, 1);
  }
  80% {
    transform: scale3d(1.5, 1.5, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.animate_pulse_clear {
  animation-name: pulse-grow-clear;
  animation-duration: 1900ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.animate_pulse {
  animation-name: pulse-grow;
  animation-duration: 1900ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.animate_fadeIn {
  animation-fill-mode: forwards;
  animation-duration: 750ms;
  animation-name: fadeIn;
}

.animate_fadeIn_quick {
  animation-fill-mode: forwards;
  animation-duration: 100ms;
  animation-name: fadeIn;
}

.animate_fadeOut_quick {
  animation-fill-mode: forwards;
  animation-duration: 300ms;
  animation-name: fadeOut;
}

.animate_fadeIn_200 {
  animation-fill-mode: forwards;
  animation-duration: 200ms;
  animation-name: fadeIn;
}

.animate_fadeOut_200 {
  animation-fill-mode: forwards;
  animation-duration: 200ms;
  animation-name: fadeOut;
}

.animate_fadeInDown {
  animation-fill-mode: forwards;
  animation-duration: 2250ms;
  animation-name: fadeInDown;
}

.animate_fadeInRight {
  animation-fill-mode: forwards;
  animation-duration: 1500ms;
  animation-name: fadeInRight;
}

.animate_fadeInRight_delay {
  animation-fill-mode: forwards;
  animation-duration: 2550ms;
  animation-name: fadeInRightDelay;
}

.animate_slideOutLeft {
  animation-fill-mode: forwards;
  animation-duration: 400ms;
  animation-name: slideOutLeft;
}

.animate_slideInRight {
  animation-fill-mode: forwards;
  animation-duration: 400ms;
  animation-name: slideInRight;
}

.hideLeft {
  animation-fill-mode: forwards;
  animation-duration: 0ms;
  animation-name: slideOutLeft;
}

body {
  background: var(--ion-color-white);
}

// @media only screen and (min-width: 767px) {
ion-content {
  background-size: cover !important;
}
// }

app-home ion-router-outlet > .ion-page {
  animation: fadeIn 1s 1 forwards;
}

app-home ion-router-outlet > .ion-page-hidden {
  animation: fadeOut 1s 1 forwards;
}

ion-popover.password-input-popover {
  --min-width: 70%;
  --offset-y: -15%;
  z-index: 40000 !important;
}

.no-pointer-events {
  &::part(content) {
    pointer-events: none;
  }
}

.scrollbar-track-y {
  display: none !important;
}

.tabs-inner {
  position: unset !important;
  contain: none !important;
}

// Onboarding

.onboarding-slide-container {
  flex: 1;
  overflow: auto;
  // height: calc(100% - 84px);
  // height: calc(100% - 84px - var(--ion-safe-area-bottom));
  height: calc(
    100% - 84px - var(--ion-safe-area-bottom) - var(--ion-safe-area-top)
  );

  &.no-footer {
    height: calc(100% - var(--ion-safe-area-bottom) - var(--ion-safe-area-top));
  }

  &.justifyStart {
    justify-content: start !important;
  }
}

.onboarding-page-title {
  font-weight: 700;
  font-size: 28px;
  min-height: 68px;
  margin: 12px 12px 12px 1rem;
  text-align: start;
  max-width: 100%;

  &.isWeb {
    min-height: 52px !important;
  }
}

.onboarding-next-btn {
  background: var(--ion-color-primary);
  --color: white;
  // --color-focused: white;
  // --color-activated: white;
  // --color: white;
  // color: white;
  border-radius: 12px;
  min-height: 52px;
  max-height: 52px;
  margin: 1.5rem 24px 0;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.07);
  // width: 100%;
}

.onboarding-picker,
.date-picker {
  .picker-wrapper {
    --background: #252525;
    --border-width: 0;
    --border-color: #e6e4ea;
  }

  .picker-toolbar {
    background: #252525;
    border-bottom: 1px solid #e6e4ea !important;
  }

  .picker-button {
    border: 0;
    color: white !important;
  }

  .picker-opts {
    font-size: 23px;

    .picker-opt {
      color: #9a99a2;
    }

    .picker-opt-selected {
      color: white;
      height: 40px;
    }
  }

  .picker-above-highlight {
    border-bottom: 1px solid #e6e4ea !important;
    background: transparent !important;
  }

  .picker-below-highlight {
    border-bottom: 1px solid #e6e4ea !important;
    background: transparent !important;
  }
}

.date-picker .picker-opt {
  text-overflow: unset !important;
}

.md .date-picker ion-picker-column {
  max-width: unset !important;
}

.onboarding-picker {
  .picker-columns {
    .picker-col {
      max-width: 50px;
    }
  }
}

.subs-popover {
  &::part(content) {
    width: calc(100% - 20px); // full width minus padding
    height: calc(100% - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
    min-height: calc(
      100% - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
    );
    max-height: calc(
      100% - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
    );
    left: calc(0px + var(--ion-safe-area-left, 0px)) !important;
    top: 0px !important;
    padding-top: var(--ion-safe-area-top);
    padding-bottom: var(--ion-safe-area-bottom);
    padding-right: 10px !important;
    padding-left: 10px !important;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0px;
    display: flex;
    justify-content: center;
  }

  &::part(backdrop) {
    opacity: 0.7 !important;
  }
}

ion-toast {
  .toast-container .subscribed-toast-button {
    pointer-events: none;
    color: var(--ion-color-success);
    width: 44px;
    height: 44px;
  }

  &.achievement-toast {
    display: contents !important;
    &::part(container) {
      padding-left: 0.5rem;
      .toast-button-group {
        width: 58px;
      }
    }

    &::part(button) {
      padding: 0px;
      border-radius: 0px;
      height: 58px;
      width: 58px;
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.subscribed {
  --button-color: var(--ion-color-success);
}

.gold {
  color: #ffeead;
}

.bold {
  font-weight: 700;
}

ion-modal.no-enter-animation {
  &::part(content) {
    transform: none;
    opacity: 1;
  }
  &::part(backdrop) {
    opacity: 1;
  }
}

.purple-modal {
  --background: rgb(51, 56, 99);
  margin-top: 64px;

  .web-container {
    overflow: hidden scroll;
    max-width: 600px;
  }
}

.cupertino-pane-wrapper.notification {
  z-index: 30000;
  .pane {
    min-height: 72px !important;
    background: transparent !important;
    margin-top: calc(var(--ion-safe-area-top) + 0.5rem) !important;
  }
}

.custom-alert {
  .alert-button-group {
    padding-top: 0px;
    button.alert-button {
      color: var(--ion-color-primary);
      width: 48px;
      height: 48px;
      white-space: nowrap;
      .alert-button-inner {
        justify-content: center;
      }
    }
  }
  z-index: 40000 !important;
}

.w-100-important {
  width: 100% !important;
}

ion-button {
  &.btn-submit {
    font-size: 17px;
  }

  &.btn-cancel {
    font-size: 17px;
  }
}

ion-progress-bar {
  --background: rgb(26, 30, 60);
  --progress-background: var(--ion-color-primary);
}

.safe-area-bg-bottom {
  position: absolute;
  bottom: 0px;
  height: var(--ion-safe-area-bottom);
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;
  width: 100%;
}

.z30000 {
  z-index: 30000 !important;
}

.swiper-pagination {
  bottom: 16px;
  --swiper-pagination-color: var(--ion-color-primary);
  --swiper-pagination-bullet-inactive-color: black;
  --swiper-pagination-bullet-inactive-opacity: 0.3;
}

.swiper-button-next,
.swiper-button-prev {
  bottom: 6px;
  top: auto;
  width: 32px;
  z-index: 1000;
  --swiper-navigation-size: 32px;
  --swiper-navigation-color: black;
}

.swiper-button-next {
  right: 10%;
}

.swiper-button-prev {
  left: 10%;
}

.segment-btn {
  cursor: pointer;
}
